import React, { useState } from "react";
import "./Auth.css";
import { useNavigate } from "react-router-dom";
const SignIn = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    if (input.username === "admin" && input.password === "admin") {
      localStorage.setItem("rool", "admin");
      navigate("/orders");
    } else {
      alert("Provide a Valid Username and Password");
    }
  };

  return (
    <div className="auth-container">
      <div className="form">
        <h2>Admin Login</h2>
        <input
          type="text"
          placeholder="Username"
          name="username"
          value={input.username}
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={input.password}
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
        />
        <button onClick={submitHandler}>Sign In</button>
        {/* <p>
          <NavLink to="/SignUp">Don’t have an account?</NavLink>
        </p> */}
      </div>
    </div>
  );
};

export default SignIn;
