import React from "react";
// import HeroSection from "../../Components/HeroSection/HeroSection";
import Category from "../../Components/Categories/Category";
import Slider from "../../Components/Slider/Slider";
const Home = () => {
  return (
    <>
      <Slider />
      {/* <HeroSection /> */}
      <Category />
    </>
  );
};

export default Home;
