import React from "react";
import "./CartScreen.css";
import { Link } from "react-router-dom";

const CartScreen = ({
  cartItems,
  handleAddProduct,
  handleRemoveProduct,
  handleClearProduct,
}) => {
  const totalPrice = cartItems.reduce(
    (price, item) => price + item.quantity * item.price,
    0
  );

  return (
    <div className="cart-container">
      <div className="allProducts">
        {cartItems.length === 0 && (
          <p style={{ textAlign: "center" }}>No more Cart Items</p>
        )}
        <>
          {cartItems?.map((item, index) => {
            return (
              <div className="cartData" key={index}>
                <div className="imgBx">
                  <img
                    src={`https://look.manalit.com/${item.thumbnail[0]}`}
                    alt=""
                  />
                  {/* <img
                    src={`https://look.manalit.com/${item.images[0]}`}
                    alt=""
                  /> */}
                </div>

                <div className="txtBx">
                  <h3>{item.title}</h3>
                  {/* <p>{item.description}</p> */}
                  <div className="quantity">
                    <span onClick={() => handleRemoveProduct(item)}>-</span>
                    <span>{item.quantity}</span>
                    <span onClick={() => handleAddProduct(item)}>+</span>
                  </div>
                  <p className="price">₹ {item.price}</p>
                  <i
                    className="fa-solid fa-trash"
                    onClick={() => handleClearProduct(item)}
                  ></i>
                </div>
              </div>
            );
          })}
        </>
        {cartItems.length > 0 && (
          <>
            <div className="totalBx">
              <p>Total Price</p>
              <h2>₹ {totalPrice}.00</h2>
              <Link to={"/place-order"} className="po-btn">
                Proceed
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CartScreen;
