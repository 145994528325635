import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Splash from "./Screens/SplashScreen/Splash";
// import data from "./Components/Data/Data";
import Routing from "./Components/Routing";
import api from "./Components/api.js";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
// import { ToastContainer } from "react-toastify";

const App = () => {
  const [loading, setloading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, [1000]);
  });
  // Initialise localStorage for cartItems.
  const prevData = localStorage.getItem("cartItems");
  const [cartItems, setCartItems] = useState(
    prevData ? JSON.parse(prevData) : []
  );

  // Function fetch all products from API.
  const fetchData = async () => {
    try {
      const result = await api.get("/all-products?_format=json");
      setData(result.data.productItems);
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    fetchData();
  }, [cartItems]);

  // const productItems = api;
  const productItems = data;

  const handleAddProduct = (product) => {
    const ProductExist = cartItems.find((item) => item.id === product.id);

    if (ProductExist) {
      setCartItems(
        cartItems.map((item) =>
        item.id === product.id
        ? { ...ProductExist, quantity: ProductExist.quantity + 1 }
        : item
        )
        );

    } else {
      setCartItems([...cartItems, { ...product, quantity: 1 }]);
    }
  };
  const handleRemoveProduct = (product) => {
    const ProductExist = cartItems.find((item) => item.id === product.id);
    if (ProductExist.quantity === 1) {
      setCartItems(cartItems.filter((item) => item.id !== product.id));
    } else {
      setCartItems(
        cartItems.map((item) =>
          item.id === product.id
            ? { ...ProductExist, quantity: ProductExist.quantity - 1 }
            : item
        )
      );
    }
  };

  const handleClearProduct = (data) => {
    let filteredArray = cartItems.filter((item) => {
      return item.id !== data.id;
    });
    setCartItems(filteredArray);
  };

  return (
    <Router>
      {/* <ToastContainer /> */}
      {loading ? (
        <Splash />
      ) : (
        <>
          <Header cartItems={cartItems} />
          {/* <React.Fragment> */}
          <Routing
            productItems={productItems}
            cartItems={cartItems}
            handleAddProduct={handleAddProduct}
            handleRemoveProduct={handleRemoveProduct}
            handleClearProduct={handleClearProduct}
          />
          {/* </React.Fragment> */}
          <Footer />
        </>
      )}
    </Router>
  );
};

export default App;
