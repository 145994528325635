import React from "react";
import "./Category.css";
import { Link } from "react-router-dom";

const Category = () => {
  const cardData = [
    {
      img: "https://nuvomagazine.scdn2.secure.raxcdn.com/wp-content/uploads/2021/02/RightHandRing-Citrine-CreditAllisonKuhl-1761-scaled.jpg",
      btnName: "Rings",
      category: "rings",
    },
    {
      img: "https://t3.ftcdn.net/jpg/02/94/05/00/360_F_294050088_PCvnqSNBoE6LiLm04nrZ4pLTJUwrxCSD.jpg",
      btnName: "Bracelet",
      category: "bracelet",
    },
    {
      img: "https://images.pexels.com/photos/5475580/pexels-photo-5475580.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      btnName: "Neckpieces",
      category: "neckpieces",
    },
    {
      img: "https://img.freepik.com/free-photo/portrait-young-woman-with-earrings-with-gems-isolated_132075-10060.jpg?w=2000",
      btnName: "Earrings",
      category: "earrings",
    },
  ];
  return (
    <div className="category">
      {cardData?.map((item, key) => {
        return (
          <>
          <div className="cat-card" key={key}>
            <img src={item.img} alt="" />
            <Link to={"/collection"}>
              <button>{item.btnName}</button>
            </Link>
          </div>
            </>
        );
      })}
    </div>
  );
};

export default Category;
