import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import api from "../../Components/api.js";
import { ToastContainer, toast } from "react-toastify";
import "./PlaceOrder.css";
// import { computeHeadingLevel } from "@testing-library/react";

const PlaceOrder = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    mobNo: "",
    email: "",
  });

  const [items, setItems] = useState([]);

  // This is array of states of India for select options.
  const states = [
    "30|Andhra Pradesh",
    "40|Arunachal Pradesh",
    "30|Assam",
    "50|Bihar",
    "20|Chhattisgarh",
    // "Goa",
    // "Gujarat",
    // "Haryana",
    // "Himachal Pradesh",
    // "Jammu and Kashmir",
    // "Jharkhand",
    // "Karnataka",
    // "Kerala",
    // "Madhya Pradesh",
    // "Maharashtra",
    // "Manipur",
    // "Meghalaya",
    // "Mizoram",
    // "Nagaland",
    // "Odisha",
    // "Punjab",
    // "Rajasthan",
    // "Sikkim",
    // "Tamil Nadu",
    // "Telangana",
    // "Tripura",
    // "Uttarakhand",
    // "Uttar Pradesh",
    // "West Bengal",
    // "Andaman and Nicobar Islands",
    // "Chandigarh",
    // "Dadra and Nagar Haveli",
    // "Daman and Diu",
    // "Delhi",
    // "Lakshadweep",
    // "Puducherry",
  ];

  const totalPrice = items.reduce(
    (price, item) => price + item.quantity * item.price,
    0
  );
  useEffect(() => {
    const jsondata = localStorage.getItem("cartItems");
    const parseData = JSON.parse(jsondata);
    setItems(parseData);
  }, [totalPrice]);

  const onChangeHandler = (e) => {
    const elem = e.target;
    setUserDetails({ ...userDetails, [elem.name]: elem.value });
  };
  const navigate = useNavigate();

  const [upi, setUpi] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (userDetails.mobNo.trim().length !== 10) {
      alert("Enter valid Phone No.");
      return;
    }

    if (userDetails.state === "") {
      alert("select an state.");
      return;
    }

    setUpi(true);
    // Send form data to API endpoint
    try {
      // Make a POST request to the API endpoint using Axios
      const response = await api.post(
        "/add-customer?_format=json", {
          'user_detail' : userDetails,
          'order_items' : JSON.parse(localStorage.getItem("cartItems")),
          'total_price' : totalPrice,
        }

      );
      // const placeOrderResponse = await api.post("/place-order-main?_format=json",{});
      // Handle the response
      // console.log(placeOrderResponse.data);
      console.log(response.data); // Do something with the response data
    } catch (error) {
      // Handle any errors
      console.error("Error:", error);
    }

    // Empty form after submit.
    // setUserDetails({
    //   name: "",
    //   address: "",
    //   city: "",
    //   state: "",
    //   mobNo: "",
    //   email: "",
    // });
  };

  const ContinueShopping = () => {
    setTimeout(() => {
      navigate("/collection");
    }, 5000);
    setUpi(false);
    localStorage.removeItem("cartItems");
    toast.success("Thank You!", {
      position: "top-center",
      autoClose: 4500,
      pauseOnHover: false,
      theme: "light",
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="cart__main">
        <div className="auth-container sm-screen">
          <div className="form sm-screen" style={{ width: "30%" }}>
            <h2 className="align-center">Shipping address</h2>
            <form action="" onSubmit={submitHandler}>
              <input
                type="text"
                onChange={onChangeHandler}
                value={userDetails.name}
                name="name"
                placeholder="Enter your name"
                required
              />
              <input
                type="text"
                onChange={onChangeHandler}
                value={userDetails.address}
                name="address"
                placeholder="Enter your address"
                required
              />
              <input
                type="text"
                onChange={onChangeHandler}
                value={userDetails.city}
                name="city"
                placeholder="Enter your city"
                required
              />
              <select
                name="state"
                value={userDetails.state}
                onChange={onChangeHandler}
              >
                <option value="">Enter your state</option>
                {states.map((item) => {
                  const cost = item.split("|");
                  return (
                    <option value={`${cost[1].slice(0, 3)}-${cost[0]}`}>
                      {cost[1]}
                    </option>
                  );
                })}
              </select>
              <input
                type="number"
                onChange={onChangeHandler}
                value={userDetails.mobNo}
                name="mobNo"
                placeholder="Enter your Mobile No."
                required
              />
              <input
                type="email"
                onChange={onChangeHandler}
                value={userDetails.email}
                name="email"
                placeholder="Enter your e-mail"
                required
              />
              <button type="submit">Add</button>
            </form>
          </div>
          <div className="cart__details">
            <table border={1}>
              <h2>PRICE DETAILS</h2>
              <tr class="price-details">
                <td>
                  <b>Product</b>
                </td>
                <th>Qty.</th>
                <th>Price</th>
              </tr>
              {localStorage.getItem("cartItems") &&
                items.map((item) => {
                  return (
                    <tr key={Math.random()}>
                      <td className="pr_item_name">{item.title} </td>
                      <td className="pr_item_qty">{item.quantity}</td>
                      <td className="pr_item_total">
                        ₹{item.price * item.quantity}
                      </td>
                    </tr>
                  );
                })}
            </table>
            <p className="ship-cost">
              Delivery charges:{" "}
              <span>₹{userDetails.state.split("-")[1] || "Select state"}</span>
            </p>
            <h2 className="cart_total">
              Total: ₹
              {localStorage.getItem("cartItems")
                ? totalPrice + Number(userDetails.state.split("-")[1]) ||
                  totalPrice
                : ""}
            </h2>
          </div>
        </div>

        {upi === true ? (
          <div className="qr-section">
            <i
              className="fa-solid fa-xmark"
              onClick={() => {
                setUpi(false);
              }}
            ></i>
            <div className="bankDetails">
              <div>
                <h2 style={{ marginBottom: "10px" }}>Shipping Details</h2>
                <p>Name: {userDetails.name}</p>
                <p>address: {userDetails.address}</p>
                <p>city: {userDetails.city}</p>
                <p>Mobile Mo.: {userDetails.mobNo}</p>
                <p>state: {userDetails.state}</p>
              </div>
              <div className="payTo__section">
                <h2 style={{ marginBottom: "10px" }}>Pay To</h2>
                <p>Seller: The Adaa Accessories</p>
                <p>Email : theadaaaccessories@gmail.com</p>
                <p>Mobile : +91 935-6358110</p>
              </div>
            </div>
            <div className="qrImg">
            <p>UPI ID: amenasadaf266@okaxis</p>
              <div>
                <img
                  src="https://cdn.pixabay.com/photo/2013/07/12/14/45/qr-code-148732_1280.png"
                  alt=""
                />
              </div>
              <button onClick={ContinueShopping}>Continue Shopping</button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PlaceOrder;
