import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../Components/api";
import Popup from "./Popup";
import "./OrderList.css";

const OrderList = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState();
  const [showPopup, setShowPopup] = useState(false);
  // this state contain data that will shown in popup.
  const [PopupData, setPopupData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Function fetch all products from API.
  const fetchData = async () => {
    try {
      const result = await api.get("/orders?_format=json");
      setOrders(result.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const storedRool = localStorage.getItem("rool");
    storedRool !== "admin" && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchData();
    console.log(orders);
  }, [navigate]);

  // const data = orders;

  // const data = [
  //   {
  //     customer_orders: [
  //       {
  //         order_item_id: "9",
  //         product_name: "Gold Bracelet",
  //         unit_price: "100.00",
  //       },
  //       {
  //         order_item_id: "10",
  //         product_name: "Gold Bracelet",
  //         unit_price: "200.00",
  //       },
  //       {
  //         order_item_id: "11",
  //         product_name: "Necklace",
  //         unit_price: "230.00",
  //       },
  //     ],
  //     orders_detail: {
  //       order_id: "8",
  //       customer_id: "233",
  //       order_total: "550.00",
  //     },
  //   },
  //   {
  //     customer_orders: [
  //       {
  //         order_item_id: "9",
  //         product_name: "Gold Bracelet",
  //         unit_price: "100.00",
  //       },
  //       {
  //         order_item_id: "10",
  //         product_name: "Gold Bracelet",
  //         unit_price: "200.00",
  //       },
  //       {
  //         order_item_id: "10",
  //         product_name: "Gold Bracelet",
  //         unit_price: "200.00",
  //       },
  //     ],
  //     orders_detail: {
  //       order_id: "9",
  //       customer_id: "233",
  //       order_total: "550.00",
  //     },
  //   },
  //   {
  //     customer_orders: [
  //       {
  //         order_item_id: "9",
  //         product_name: "Gold Bracelet",
  //         unit_price: "100.00",
  //       },
  //       {
  //         order_item_id: "10",
  //         product_name: "Gold Bracelet",
  //         unit_price: "200.00",
  //       },
  //       {
  //         order_item_id: "11",
  //         product_name: "Necklace",
  //         unit_price: "230.00",
  //       },
  //     ],
  //     orders_detail: {
  //       order_id: "7",
  //       customer_id: "233",
  //       order_total: "550.00",
  //     },
  //   },
  // ];

  // This is for toggeling popup.

  const showHidePopupHandler = (arg) => {
    // if data == null it means it is hidePopupHandler.
    if (arg === null) {
      setShowPopup(false);
      setPopupData(null);
      return;
    }
    // else it is showPopupHandler.
    setPopupData(arg);
    setShowPopup(true);
  };
  const LogoutHanlder = () => {
    localStorage.removeItem("rool");
    navigate("/sign-in");
  };
  return (
    <div className="order__page">
      <div className="orders__header">
        <h2>Orders List</h2>
        <button onClick={LogoutHanlder}>Log out</button>
      </div>
      <div className="main">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {orders.length > 0 ? (
              orders.map((order) => (
                // Render the order details here
                <li
                  key={order.orders_detail.id}
                  onClick={() => showHidePopupHandler(order)}
                >
                  <p>Order ID: {order.orders_detail.order_id}</p>
                  <p>Customer Name: {order.orders_detail.customer_name}</p>
                  <p>Mobile: {order.orders_detail.mobile_no}</p>
                  <p>Total: {order.orders_detail.order_total}</p>
                  {/* Render other order details */}
                </li>
              ))
            ) : (
              <p>No orders found.</p>
            )}
          </div>
        )}
      </div>
      {showPopup && (
        <Popup data={PopupData} hidePopupHandler={showHidePopupHandler} />
      )}
    </div>
  );
};

export default OrderList;
