import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Products from "../Screens/ProductsScreen/Products";
import Home from "../Screens/HomeScreen/Home";
import CartScreen from "../Screens/CartScreen/CartScreen";
import SignIn from "../Screens/auth/SignIn";
// import SignUp from "../Screens/auth/SignUp";
import PlaceOrder from "../Screens/place-order/PlaceOrder";
import Contact from "../Screens/ContactScreen/Contact";
import OrderList from "../Screens/OrderList/OrderList";

const Routing = ({
  productItems,
  cartItems,
  handleAddProduct,
  handleRemoveProduct,
  handleClearProduct,
}) => {
  const [rool, setRool] = useState(localStorage.getItem("rool"));

  return (
    <React.Fragment>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/collection"
          element={
            <Products
              productItems={productItems}
              handleAddProduct={handleAddProduct}
            />
          }
        />
        <Route
          exact
          path="/cart"
          element={
            <CartScreen
              cartItems={cartItems}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
              handleClearProduct={handleClearProduct}
            />
          }
        />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/sign-in" exact element={<SignIn />} />
        {/* <Route path="/signup" exact element={<SignUp />} /> */}
        <Route path="/place-order" exact element={<PlaceOrder />} />

        {rool === "admin" && (
          <Route path="/orders" exact element={<OrderList />} />
        )}
      </Routes>
    </React.Fragment>
  );
};

export default Routing;
