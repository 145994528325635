import axios from "axios";

const username = "api_master";
const password = "pass@2023";
const authHeader = `Basic ${btoa(`${username}:${password}`).toString("base64")}`;

const instance = axios.create({
  baseURL: "https://look.manalit.com/api",
  // baseURL: "http://look.local:8100/api",
  headers: {
    Authorization: authHeader,
    "Content-Type": "application/json",
  },
});

export default instance;
