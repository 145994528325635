import React from "react";
import "./Products.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Products = ({ productItems, handleAddProduct }) => {
  console.log(productItems);
  return (
    <div className="product-container">
      {productItems?.map((productItem, index) => {
        return (
          <div className="product-card" key={index}>
            <div className="imgBx">
              <LazyLoadImage
                alt="demonstration1"
                effect="blur"
                width={250}
                height={250}
                src={`https://look.manalit.com/${productItem.thumbnail[0]}`}
              />
            </div>
            <div className="detailsBx">
              <p className="title text_lg">{productItem.title}</p>
              {/* <p className="text_md"> {productItem.description}</p> */}
              <p className="title text_lg">₹ {productItem.price}</p>
              <button
                className="orange-bg"
                onClick={() => handleAddProduct(productItem)}
              >
                Add to Cart
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Products;
