import React from "react";
import "./Contact.css";
const Contact = () => {
  const data = [
    {
      icon: "fa-solid fa-location-dot",
      title: "Location",
      detail: "Contact DUMMY Contact DUMMY",
    },

    {
      icon: "fa-solid fa-phone",
      title: "Phone",
      detail: "+91 935-6358110",
    },
    {
      icon: "fa-solid fa-fax",
      title: "Tele",
      detail: "Contact DUMMY Contact DUMMY",
    },
    {
      icon: "fa-solid fa-envelope",
      title: "Email",
      detail: "theadaaaccessories@gmail.com",
    },
  ];
  return (
    <div className="contact">
      {/* <h1>Contact Us</h1> */}

      <div className="contactBx">
        {data.map((item, key) => {
          return (
            <div className="cardBx" key={key}>
              <i className={`${item.icon}`}></i>
              <p>{item.title}</p>
              <p>{item.detail}</p>
            </div>
          );
        })}
      </div>
      <div className="contact-form">
        <h1>Contact Us</h1>
        <form action="">
          <input type="text" placeholder="Enter Your Name" />
          <input type="text" placeholder="Enter Email" />
          <textarea name="" id="" cols="30" rows="10" placeholder="Message"></textarea>
          <button>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
