import React, { useState } from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";

const Header = ({ cartItems }) => {
  const [openNav, setOpenNav] = useState(true);
  const toggle = () => {
    setOpenNav(!openNav);
  };
  const navData = [
    { title: "Home", url: "/", exact: true },
    { title: "Collection", url: "/collection", exact: true },
    { title: "Contact", url: "/contact", exact: true },
    // { title: "Collection", url: "/collection", exact: true },
  ];
  return (
    <header>
      <button className="toggleBtn" onClick={toggle}>
        <div></div>
        <div></div>
        <div></div>
      </button>
      <div className="logo">
        <NavLink to={"/"}>
          <h1>
            Adaa <span>Accessories</span>
          </h1>
        </NavLink>
      </div>
      <div
        className="navLinks"
        style={!openNav ? { left: "0px" } : { left: "-50%" }}
      >
        {navData.map((item, index) => {
          return (
            <li key={index} onClick={() => setOpenNav(true)}>
              <NavLink
                to={item.url}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {item.title}
              </NavLink>
            </li>
          );
        })}
        {localStorage.getItem('rool') === "admin" ?  <li><NavLink to={'/orders'}>Orders</NavLink></li> : " "}
      </div>

      <div className="rightNav">
        <NavLink to={localStorage.getItem('rool') === "admin" ? "/orders" : "/sign-in"}>
          <i className="fa-solid fa-user"></i>
        </NavLink>
        {/* <i className="fa-solid fa-heart"></i> */}
        <NavLink to="/cart">
          <div className="cart">
            <span className="cartItem">
              {cartItems.length === 0 ? "0" : cartItems.length}
            </span>
            <i className="fa-solid fa-bag-shopping cartBtn"></i>
          </div>
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
