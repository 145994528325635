import React from "react";
import "./Popup.css";

const Popup = (props) => {
  const total = props.data.customer_orders.reduce((total, cur) => {
    return (total += Number(cur.unit_price));
  }, 0);

  return (
    <>
      <div
        className="back__lay"
        onClick={() => props.hidePopupHandler(null)}
      ></div>
      <div className="popup__main">
        <button onClick={() => props.hidePopupHandler(null)}>
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div style={{ paddingTop: "2rem" }}>
          <h2>Details</h2>

          <ul>
            {props.data.customer_orders.map((item, i) => {
              return (
                <li key={i}>
                  Item id: {item.order_item_id},Product: {item.product_name},
                  Unit Price: {item.unit_price}
                </li>
              );
            })}
          </ul>
        </div>
        <h3 className="total">Total: {total}</h3>
      </div>
    </>
  );
};

export default Popup;
