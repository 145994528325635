import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className="contant">
        <p>
          <i className="fa-solid fa-envelope"></i> theadaaaccessories@gmail.com
        </p>
        <p>
          <i className="fa-solid fa-phone"></i> +91 935-6358110
        </p>
      </div>
      <div className="menuLinks">
        <h3>Category</h3>
        <li>
          <NavLink to="/collection">All Jewelry</NavLink>
        </li>
        <li>
          <NavLink to="/collection">Rings</NavLink>
        </li>
        <li>
          <NavLink to="/collection">Bracelet</NavLink>
        </li>
        <li>
          <NavLink to="/collection">Neckpieces</NavLink>
        </li>
        <li>
          <NavLink to="/collection">Earrings</NavLink>
        </li>
      </div>
    </footer>
  );
};

export default Footer;
