import React, { useState, useEffect } from "react";
import "./Slider.css";

const Slider = () => {
  const [current, setCurrent] = useState(0);

  const images = [
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/b7479764666299.5ad98abfed668.jpg",
    "https://www.oberlo.com/media/1667509251-saja-beraam-a7xy_sd1a8w-unsplash.jpg",
    "https://www.visualeducation.com/wp-content/uploads/2019/02/metal-necklace-jewellery-photograph.jpg",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent((current + 1) % images.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [current, images.length]);
  return (
    <div className="slider">
      {/* <div className="slides">
        <img src={images[0]} alt="" />
      </div> */}
      {images.map((item, index) => (
        <img
          key={index}
          src={item}
          alt={`slide-${index}`}
          className={`slide ${index === current ? "active" : ""}`}
        />
      ))}
    </div>
  );
};

export default Slider;
